import { defineStore } from "pinia";
import { useMixed } from '@/composables/mixed';

export const useLoansStore = defineStore('loans', {
	state: () => ({
		loans: [],
		loanSelected: null,
		loanRefresh: false,
		modalPaymentOverdueOpen: false,
		modalPaymentExtraOpen: false,
		modalPaymentFullOpen: false,
		modalPaymentFailOpen: false,
		modalPaymentSuccessOpen: false,
		loanDrawdownMethod: '',
		loanDrawdownMessage: '',
		paymentProcessing: false
	}),

	getters: {
		getLoans(state) {
			return state.loans.filter(loan => loan.loan_type !== "LOC");
		},

		getLoc(state) {
			return state.loans.filter(loan => loan.loan_type === "LOC");
		},

		getCurrentLoans() {
			return [...this.getLoc.filter(loan => loan.status === "Active"), ...this.getLoans.filter(loan => loan.status === "Active")];
		},

		getSettledLoans() {
			return [...this.getLoc.filter(loan => loan.status === "Settled"), ...this.getLoans.filter(loan => loan.status === "Settled")];
		},

		getPastLoans() {
			return this.getLoans.filter(loan => loan.status !== "Active").sort((d1, d2) => new Date(d2.lending_date).getTime() - new Date(d1.lending_date).getTime());
		},

		getFailedLoans() {
			return this.getLoans.filter(loan => loan.status === "Failed");
		},

		getLatestLoans() {
			return this.getLoans.sort((d1, d2) => new Date(d2.lending_date).getTime() - new Date(d1.lending_date).getTime());
		},

		countLoans(state) {
			return state.loans.length;
		},

		countLoc() {
			return this.getLoc.length;
		},

		countCurrentLoans() {
			return this.getCurrentLoans.length;
		},
		
		countSettledLoans() {
			return this.getSettledLoans.length;
		},

		countPastLoans() {
			return this.getPastLoans.length;
		},

		getLoanDrawdownMethod(state) {
			return state.loanDrawdownMethod;
		},

		getLoanDrawdownMessage(state) {
			return state.loanDrawdownMessage;
		},

		getPaymentProcessing(state) {
			return state.paymentProcessing;
		}

	},

	actions: {
		setLoans(val) {
			this.loans = val;
		},

		filterLoan(id) {
			this.loanSelected = this.loans.filter(loan => loan.id === id)
		},

		checkLoanTypeLoc(loan) {
			return Object.keys(loan).includes('loan_type') && loan.loan_type == 'LOC';
		},

		getLoanStatus(id) {
			const { formatStatusName } = useMixed();
			let loanById = this.loans.filter(loan => loan.id == id)[0];
			let loanStatus = loanById.status.toLowerCase();
			let loanStatusLabel = loanById.status;
			let loanStatusPrefix = this.checkLoanTypeLoc(loanById) ? 'loc-status-' : 'loan-status-';
			let loanStatusClass = formatStatusName(loanStatusPrefix, loanById.status);
			if (this.checkLoanTypeLoc(loanById)) {
				if (!loanById.account_open) {
					loanStatus = loanById.status == 'Settled' ? 'settled' : 'locked';
					loanStatusLabel = loanById.status == 'Settled' ? loanById.status : 'Locked';
					loanStatusClass = loanById.status == 'Settled' ? 'loc-status-settled' : 'loc-status-locked';
				} else {
					if (!loanById.eligible_for_drawdown) {
						loanStatus = 'paused';
						loanStatusLabel = 'Paused';
						loanStatusClass = 'loc-status-paused';
					} else {
						loanStatus = 'active';
						loanStatusLabel = 'Active';
						loanStatusClass = 'loc-status-active';
					}
				}
			} else {
				if (loanById.status == 'Active') {
					loanStatus = 'active';
					loanStatusLabel = 'On Time';
					loanStatusClass = 'loan-status-active';
					if (loanById.pending_payment) {
						loanStatus = 'pending';
						loanStatusLabel = 'Pending';
						loanStatusClass = 'loan-status-pending';
					}
					if (loanById.overdue > 0) {
						loanStatus = 'overdue';
						loanStatusLabel = 'Overdue';
						loanStatusClass = 'loan-status-overdue';
					}
					if (loanById.final_notice && !loanById.agreed_hardship) {
						loanStatus = 'final';
						loanStatusLabel = 'Final Notice';
						loanStatusClass = 'loan-status-final-notice';
					}
					if (loanById.agreed_hardship) {
						loanStatus = 'hardship';
						loanStatusLabel = 'Variation';
						loanStatusClass = 'loan-status-pending';
					}
				}
			}
			return { loanStatus, loanStatusLabel, loanStatusClass };
		},


		getLocAvailableCredit(loc) {
			const { formatCurrency } = useMixed();
			if (loc.account_open) {
				return loc.eligible_for_drawdown ? formatCurrency(loc.available_credit) : 'Paused';
			} else {
				return loc.status == 'Settled' ? 'Settled' : 'Locked';
			}
		},

		setModalPaymentOverdueOpen(val) {
			this.modalPaymentOverdueOpen = val;
		},

		setModalPaymentExtraOpen(val) {
			this.modalPaymentExtraOpen = val;
		},

		setModalPaymentFullOpen(val) {
			this.modalPaymentFullOpen = val;
		},

		setModalPaymentFailOpen(val) {
			this.modalPaymentFailOpen = val;
		},

		setModalPaymentSuccessOpen(val) {
			this.modalPaymentSuccessOpen = val;
		},

		setLoanRefresh(val) {
			this.loanRefresh = val;
		},

		clearLoans() {
			this.loans = [];
		},

		setLoanDrawdownMethod(val) {
			this.loanDrawdownMethod = val;
		},

		setLoanDrawdownMessage(val) {
			this.loanDrawdownMessage = val;
		},

		setPaymentProcessing(val) {
			this.paymentProcessing = val;
		}

	},
})