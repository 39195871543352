<template>
  <ion-page class="ion-page-white">
    <ion-content>
      <start-header></start-header>
      <div class="p-9 max-w-xl mx-auto">
        <h2 class="text-[22px] font-bold pt-6 pb-4 text-center fadein-900">
          <span v-if="firstName">Welcome Back <span class="ion-text-primary">{{ firstName }}</span></span>
          <span v-else>Welcome Back</span>
        </h2>
        <div v-if="attemptsReached">
          <ion-note slot="helper" class="w-full p-3 my-6 rounded block bg-notice-red">
            <div class="flex items-center justify-between">
              <ion-icon src="/assets/general/notice-danger.svg" class="m-1 w-[20px] h-[20px]"></ion-icon>
              <span class="text-sm text-notice-red p-1 inline-block">
                You've reached the maximum attempts.
              </span>
              <span class="m-1 w-[20px] h-[20px]"></span>
            </div>
          </ion-note>
          <p class="text-sm text-center">
            You can log in with your email and password.
          </p>
        </div>
        <div class="fadein-900" v-else>
          <p class="text-sm pb-3 text-center">Enter PIN Code</p>
          <numeric-keypad ref="pinCodeRef" type="check" length="4" :completed="completed" :match="match"
            :confirmed="confirmed" :count="count" :loading="loading" @keypadChange="keypadChange"
            @keypadComplete="keypadComplete" />
          <button fill="clear" class="block mx-auto" @click="keypadConfirm" v-if="completed && !loading && !confirmed">
            <span class="ion-text-primary underline font-bold">OK</span>
          </button>
        </div>
      </div>
    </ion-content>
    <ion-footer class="ion-no-border shadow-none">
      <div class="px-8 py-6">
        <div v-if="confirmed || attemptsReached">
          <div v-if="match">
            <div class="w-full flex items-center justify-center mb-9 ion-text-primary text-sm" v-if="loading">
              <span class="mr-2">Logging in</span>
              <ion-spinner name="dots" class="opacity-50" />
            </div>
          </div>
          <div v-else>
            <div v-if="count < 3">
              <ion-note slot="helper" class="w-full p-3 mb-6 rounded block bg-notice-red">
                <div class="flex items-center justify-between">
                  <div>
                    <ion-icon src="/assets/general/notice-danger.svg"
                      class="m-1 float-left w-[20px] h-[20px]"></ion-icon>
                    <span class="text-xs text-notice-red p-1 inline-block">
                      {{ count }} Failed Passcode Attempt{{ count > 1 ? 's' : '' }}
                    </span>
                  </div>
                  <button fill="clear" class="mr-3" @click="keypadClear">
                    <span class="ion-text-primary underline font-bold">Try again?</span>
                  </button>
                </div>
              </ion-note>
            </div>
            <div v-else>
              <ion-note slot="helper" class="w-full p-3 my-6 rounded block bg-notice-red" v-if="!attemptsReached">
                <div class="flex items-center justify-between">
                  <ion-icon src="/assets/general/notice-danger.svg" class="m-1 w-[20px] h-[20px]"></ion-icon>
                  <span class="text-xs text-notice-red p-1 inline-block">
                    You've reached the maximum attempts.
                  </span>
                  <span class="m-1 w-[20px] h-[20px]"></span>
                </div>
              </ion-note>
              <span class="block ion-text-grey text-center text-sm">Forgot Pin Code?</span>
              <button class="button-purple mt-3 mb-6" @click="logInWithPassword">
                <span class="ion-text-white font-bold">Login with Password</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import router from '@/router';
import { asyncComputed } from '@vueuse/core';
import StartHeader from '@/components/start/StartHeader.vue';
import NumericKeypad from '@/components/misc/NumericKeypad.vue';
import { useSecureStorage } from '@/composables/secureStorage';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores';
import { AdjustEvents, trackEvent } from "@/composables/adjust";
import { useMixed } from '@/composables/mixed';
const pinCodeRef = ref();
const { refreshCustomer } = useMixed();
const customerStore = useCustomerStore();
const { updateAuth } = customerStore;
const { name } = storeToRefs(customerStore);
const { keys, getStorage, setStorage } = useSecureStorage();
const otpStorage = asyncComputed(async () => await getStorage({ key: keys.otp }), null);
const nameStorage = asyncComputed(async () => await getStorage({ key: keys.name }), null);
const attemptsStorage = asyncComputed(async () => await getStorage({ key: keys.attempts }), null);
const loading = ref(false);
const entered = ref(null);
const confirmed = ref(false);
const count = ref(0);
const completed = ref(false);
const attemptsReached = ref(false);
const attemptsCount = computed(() => attemptsStorage.value ? attemptsStorage.value.value : count.value);
const match = computed(() => {
  if (otpStorage.value && { ...otpStorage.value }.value == entered.value && confirmed.value) {
    trackEvent(AdjustEvents.LOGIN_SUCCESSFUL);
    return true;
  }
  return false;
});
const firstName = computed(() => nameStorage.value ? nameStorage.value.value : name.value);
const keypadChange = (v) => {
  completed.value = false;
  entered.value = v;
  confirmed.value = false;
};
const keypadComplete = (v) => {
  completed.value = true;
  entered.value = v;
};
const keypadConfirm = () => {
  confirmed.value = true;
  if (!match.value) {
    count.value++;
    setStorage({ key: keys.attempts, value: JSON.stringify(count.value) });
  } else {
    logIn();
    setStorage({ key: keys.attempts, value: "" });
  }
};
const keypadClear = () => {
  keypadChange(null);
  pinCodeRef.value.keypadClear();
};
const logInWithPassword = () => {
  router.push('login-email');
};
const logIn = () => {
  trackEvent(AdjustEvents.CLICK_LOGIN);
  loading.value = true;
  refreshCustomer(() => {
    updateAuth(true);
    router.push('home');
    setTimeout(() => {
      loading.value = false;
    }, 500);
  });
};
onMounted(() => {
  if (!Capacitor.isNativePlatform()) {
    router.push('start');
  }
});
watch(() => attemptsCount.value, () => {
  if (attemptsStorage.value) {
    count.value = Number(attemptsStorage.value.value);
    if (count.value >= 3) {
      attemptsReached.value = true;
    }
  }
}, { immediate: true });
</script>